/* svg.banner {
  width: 100%; 
 height: auto;
} */



      html {
    height: 100%;
    /* height: -webkit-fill-available; */
  }   

  .App {
    overscroll-behavior: none;    
  }
  
  .header-nav {
    position: fixed; 
    z-index: 100000;   
  }
  .rectangle {
  position: fixed;
  width: 4vw;
  height: 34vh;
  left: 0vw;
  }
  
  .one {
    top: 0vh;
  background: #000000;
  }
  
  .two {
  top: 33vh;
  background: #A5A5A5;
  
  }
  
  .three {
  top: 66vh;
  background: #FFFFFF;
  }
  
  .header-item {    
    margin-top: 6vh;
  height: auto;
  width: 12vw;   
  font-family: 'Archivo', sans-serif;
  font-size: 0.75rem;
  text-align: right;
  transform: rotate(-90deg);
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  /* left: -6vw; */
  }
  
  .about-item {    
  position: absolute;    
  top: 5.5vh;
  color: #FFFFFF;
  }
  
  .digital-item { 
  position: absolute;
  top: 5.5vh;
  color: #000000;
  }
  
  .print-item {    
  position: absolute; 
  top: 5.5vh;
  color: #000000;
  }
  

  .about-container { 
    width: 100vw;
    height: 100vh;
    background-color: #000000;
    display: flex;
    flex-direction: column;
    min-height: 100vh;  
    }
    

    .laura-name {
      font-weight: 600;      
    }

    .info {
    position: absolute;
    width: 20vw;
    height: 5.333vh;
    left: 5.8vw;
    top: 3vh;
    font-family: 'Archivo', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.75em;
    line-height: 1.3125em;
    color: #FFFFFF;
    }
    
    .left-text-box {
    position: absolute;
    width: 24.9640288vw;
    height: 5.333vh;
    left: 27.6388888889vw;
    top: 3vh;
    font-family: 'Archivo', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 0.75em;
    line-height: 1.3125em;
    color: #FFFFFF;    
    }
    
    .bio {
        position: absolute;
    width: 44.2vw;
    height: 41.7vh;
    top: 2vh;
    font-size: 0.75em;
    font-family: 'Archivo', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3125em;
    color: #FFFFFF;
    left: 53.6vw;
    }
    
    .laura-font-about {
        position: relative;
        bottom: 0;   
        width: 95vw;
        left: 4.2vw;  
        height: 34vh;  
        filter: invert(99%) sepia(1%) saturate(0%) hue-rotate(257deg) brightness(105%) contrast(100%);
    }
    
    .brand-italic {
        font-style: italic;
        font-weight: 800;
    }
  
  .print-container {
    width: 100vw;    
    background: #FFFFFF;
    font-family: 'Archivo', sans-serif;
    display: flex;
    flex-direction: column;
    min-height: 100vh;  
    /* min-height: 100%;     */
    }

    .content-wrap {
      flex:1;
      /* margin-bottom: 1em; */
    }



    .project-image-landscape {
      width: 16.5625em;    
        height: 10.25em; 
        display: inline-block;
        margin-right: 2vw;
        margin-top: 1vh;
    }

    .project-image-portrait {
     height: 17em;        
        width: 12.8125em;
        display: inline-block;
        margin-right: 2vw;
        margin-top: 1vh;
    }

    /* .project-image-hover {
      width:
    } */
  

/* DIGITAL */
    .digital-container {
      width: 100vw;
      background-color: #A5A5A5;
      font-family: 'Archivo', sans-serif; 
      display: flex;
    flex-direction: column;
    min-height: 100vh;
      }
      
      .column-label-left {
          position: relative;
      width: 10vw;
      height: 1.77777778vh;
      left: 88.8vw;
      font-family: 'Archivo', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 0.75em;
      line-height: 0.5em;
      color: #000000;
      text-align: right;
      }       
      
      .title-heading {
          top: 4vh;
      }
      
      .projects-underline {
      position: absolute;
      width: 92vw;
      height: 0px;
      left: 5.8vw;
      top: 6vh;
      
      border: 1px solid #000000;
      }
      
      .projects-table {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          position: relative;
          width: 92vw;
          left: 5.8vw;    
          top: 5vh; 
          overflow: scroll;                      
      }
      
      .project {
          display: grid;
          grid-template-columns: 12fr 4fr;          
          line-height: 1.3125em;
          width: 92vw; 
          font-style: normal;
          font-weight: 400;
          font-size: 0.75em;
          border-bottom: 0.05em solid transparent;
      }
      
      .project:hover {
          border-bottom: 0.05em solid #000000;    
      } 
      
      .link-edit {
          color: #000000;
          text-decoration: none;
      }
      
      .client-column {            
          position: relative; 
          margin-left: 10vw;
          text-align: right;          

          
      }   


          .laura-font-digital {
              position: relative;            
              width: 95vw;
              left: 4.2vw;        
              height: 34vh;
              bottom: 0vh; 
              display: block;
              margin-top: 3em;
              }
      
          #project-header {
              position: absolute;
              height: 1vh;
              width: 88.25vw;
              left: 5.8vw;
              font-family: 'Archivo', sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: 0.75em;
              line-height: 0.5em;
              color: #000000;
              } 

                          
/* iPhone 5SE */
            @media only screen 
and (min-device-width: 320px) 
and (min-device-height: 568px)
and (orientation: portrait)
/* and (-webkit-min-device-pixel-ratio: 2) */
 {
  


    

  .laura-font-about {
    position: sticky;
    bottom: 6vh;
    width: 90vw;
    left: 6vw;
    height: 11vh;    
    filter: invert(99%) sepia(1%) saturate(0%) hue-rotate(257deg) brightness(105%) contrast(100%);    
}

.laura-name {
      font-weight: 600;      
    }

.laura-font-digital {
              position: sticky;            
              width: 90vw;
              left: 6vw;        
              height: 11vh;
              bottom: 6vh;              
              margin-top: 4em;               
              }

  .about { 
    width: 100vw;
    height: 100vh;
    background-color: #000000;
    }  
  .info {
    position: absolute;
    width: 60vw;
    height: 5.333vh;
    left: 5.8vw;
    top: 3vh;
    font-family: 'Archivo', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.6rem;
    line-height: 1.3125em;
    color: #FFFFFF;
    }

    .left-text-box {
      position: absolute;
      width: 60vw;
      height: 5.333vh;
      left: 5.8vw;
      top: 12vh;
      font-family: 'Archivo', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 0.6rem;
      line-height: 1.3125em;
      color: #FFFFFF;
      }

      .bio {
        position: absolute;
    width: 88vw;    
    top: 24vh;
    font-size: 0.6rem;
    font-family: 'Archivo', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3125em;
    color: #FFFFFF;
    left: 5.8vw;
    }

.header-nav {
    position: fixed; 
    z-index: 100000;   
  }

  .rectangle {
    position: fixed;
    /* top: 94vh; */
    bottom: 0;
    width: 33.33vw;
    height: 6vh;
    /* margin-bottom: 6vh;            */    
    }

    .one {
      background: #000000; 
      top: unset;  
      overflow: hidden;   
      }
      
      .two {
      left: 33vw;
      background: #A5A5A5;
      top: unset;
      overflow: hidden;
      }
      
      .three {
      left: 66vw;
      background: #FFFFFF;
      top: unset;
      overflow: hidden;
      }
      .header-item {
        margin-top: 0vh;
        height: 4vh;
        width: 33vw;   
        font-family: 'Archivo', sans-serif;
        font-size: 0.6rem;
        text-align: center;
        transform: rotate(0deg);
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        /* left: -0.4vw;  */
        top: 2.2vh;       
        }

        .about-item {    
          position: absolute;            
          color: #FFFFFF;
          }
          
          .digital-item { 
          position: absolute;                
          color: #000000;
          
          }
          
          .print-item {    
          position: absolute; 
          color: #000000;
          }          

          .digital {
            width: 100vw;
            height: 100vh;
            background-color: #A5A5A5;
            font-family: 'Archivo', sans-serif;
            }
            
            .column-label-left {
                position: absolute;
            width: 19.7841726619vw;
            height: 1.77777778vh;
            left: 74vw;
            font-family: 'Archivo', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 0.6em;
            line-height: 1.2em;
            color: #000000;
            }       
            
            .title-heading {
                top: 4vh;
            }
            
            .projects-underline {
            position: absolute;
            width: 87.6vw;
            height: 0px;
            left: 5.8vw;
            top: 6vh;
            
            border: 1px solid #000000;
            }
            
            .projects-table {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                position: relative;
                width: 90.4vw;
                left: 5.8vw;    
                top: 6.4vh;    
            }
            
            .project {
                display: grid;
                grid-template-columns: 2fr 1fr;                
                line-height: 1.25em;
                width: 88vw; 
                font-style: normal;
                font-weight: 400;
                font-size: 0.6em;
                border-bottom: 0.05em solid #000000;  
                padding-top: 0.2em; 
                padding-bottom: 0.2em; 
            }          
            
            .link-edit {
                color: #000000;
                text-decoration: none;
            }
            
            .client-column {  
                position: relative;  
                margin-left: 10vw;
                text-align: right;                               
            }       
                        
            
                #project-header {
                    position: absolute;
                    height: 1vh;
                    width: 88.25vw;
                    left: 5.8vw;
                    font-family: 'Archivo', sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 0.6em;
                    line-height: 1.2em;
                    color: #000000;
                    }

    .project-image-landscape {
      width: 17.8em;
        height: 12em;
        display: inline-block;
        margin-right: 0;        
        margin-top: 1vh;        
    }

    .project-image-portrait {
      width: 10em;
        height: 14em;
        display: inline-block;
        margin-right: 2vw;
        margin-top: 1vh;
    }

    .content-wrap {
      flex:1;
      margin-bottom: 0.88em;
    }
   
}



@media only screen 
and (min-device-width: 568px)
and (min-device-height: 320px)
and (orientation: landscape)
/* and (-webkit-min-device-pixel-ratio: 2) */
 {
  .info {
    position: absolute;
    width: 20vw;
    height: 5.333vh;
    left: 5.8vw;
    top: 3vh;
    font-family: 'Archivo', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.5em;
    line-height: 1.25em;
    color: #FFFFFF;    
    }

    .left-text-box {
      position: absolute;
      width: 24.9640288vw;
      height: 5.333vh;
      left: 27.6388888889vw;
      top: 3vh;
      font-family: 'Archivo', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 0.5em;
      line-height: 1.25em;
      color: #FFFFFF;
      }

      .bio {
      position: absolute;
    width: 44.2vw;
    height: 41.7vh;
    top: 2vh;
    font-size: 0.6em;
    font-family: 'Archivo', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25em;
    color: #FFFFFF;
    left: 53.6vw;
    }

    .header-item {
      margin-top: 4vh;
    height: 8vh;
    width: 12vw;   
    font-family: 'Archivo', sans-serif;
    font-size: 0.5rem;
    text-align: right;
    transform: rotate(-90deg);
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    left: -2.4vw;
    }

.projects-underline {
      position: absolute;
      width: 89.8vw;
      height: 0px;
      left: 5.8vw;
      top: 6vh;
      
      border: 0.5px solid #000000;
}

    .column-label-left {
      position: relative;
  width: 10vw;
  height: 1.77777778vh;
  left: 85.6vw;
  font-family: 'Archivo', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.5em;  
  color: #000000;
  } 

  .project {
    display: grid;
    grid-template-columns: 12fr 4.4fr;          
    line-height: 1.25em;
    width: 90vw; 
    font-style: normal;
    font-weight: 400;
    font-size: 0.5em;
    border-bottom: 0.05em solid transparent;
}      
#project-header {
  position: absolute;
  height: 1vh;
  width: 88.25vw;
  left: 5.8vw;
  font-family: 'Archivo', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.5em;  
  color: #000000;
  } 

  .laura-font-about {
    position: sticky;
    bottom: 4vh;
    width: 90vw;
    left: 6vw;
    height: 24vh;    
    /* margin-top: 2.6em; */
    filter: invert(99%) sepia(1%) saturate(0%) hue-rotate(257deg) brightness(105%) contrast(100%);    
}


.laura-font-digital {
              position: sticky;            
              width: 90vw;
              left: 6vw;        
              height: 24vh;
              bottom: 4vh;              
              margin-top: 2.2em;               
              }

}

@media only screen 
and (min-device-width: 375px) 
and (min-device-height: 667px)
and (orientation: portrait)
/* and (-webkit-min-device-pixel-ratio: 2) */
 {

  
    .header-item {          
      text-align: center;
      top: 2.4vh;      
      }
  .rectangle {
    position: fixed;
    /* top: 94vh; */
    width: 34vw;
    bottom: 0;
    height: 6.2vh;
    overflow: hidden;
    /* margin-bottom: 6.2vh;         */
    }
            .column-label-left {
                position: absolute;
            width: 19.7841726619vw;
            height: 1.77777778vh;
            left: 74vw;
            font-family: 'Archivo', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 0.6em;
            line-height: 1.2em;
            color: #000000;
          }                                                 

          .client-column {  
            position: relative;  
            margin-left: 8vw;
            text-align: right;                               
        }  

        .laura-font-digital {            
              margin-top: 4.8em;
              }                                                                  
}

@media only screen 
and (min-device-height: 375px) 
and (min-device-width: 667px)
and (orientation: landscape)
/* and (-webkit-min-device-pixel-ratio: 2) */
 { 
.laura-font-digital {            
              margin-top: 2.6em;              
              } 
 }

/* iPhone 678+ */
@media only screen 
and (min-device-width: 414px) 
and (min-device-height: 736px)
and (orientation: portrait)
/* and (-webkit-min-device-pixel-ratio: 2) */
 {        

   .header-item {          
      text-align: center;
      top: 2.4vh;      
      }      
   
  .info, .left-text-box, .bio, .header-item, .column-label-left, .project, #project-header {
    font-size: 0.66rem;
  }    
     .column-label-left {
      position: absolute;
  width: 19.7841726619vw;
  height: 1.77777778vh;
  left: 74vw;
  font-family: 'Archivo', sans-serif;
  font-style: normal;
  font-weight: 500;  
  line-height: 1.2em;
  color: #000000;
  }                                                 

  .client-column {  
    position: relative;  
    margin-left: 10vw; 
    text-align: right;                              
}    



.laura-font-digital {            
              margin-top: 6em;
              }  
}

@media only screen 
and (min-device-height: 414px) 
and (min-device-width: 736px)
and (orientation: landscape)
/* and (-webkit-min-device-pixel-ratio: 2) */
 { 
.laura-font-digital {            
              margin-top: 2.6em;              
              } 
 }

/* iPhone X */

@media only screen 
and (min-device-width: 375px) 
and (min-device-height: 812px)
and (orientation: portrait)
/* and (-webkit-min-device-pixel-ratio: 2) */
{     

  .header-item {          
      text-align: center;
      top: 2.4vh;      
      }         
   
  .info, .left-text-box, .bio, .header-item, .column-label-left, .project, #project-header {
    font-size: 0.8rem;
  }   
  .project-image-landscape {      
        width: 20.8em;
        height: 14em;        
    }

    .project-image-portrait {
      height: 20.8em;
        width: 14em;          
    }

    .laura-font-digital {            
              margin-top: 6em;
              } 
}

@media only screen 
and (min-device-height: 375px) 
and (min-device-width: 812px)
and (orientation: landscape)
/* and (-webkit-min-device-pixel-ratio: 2) */
{
            
   
  .info, .left-text-box, .bio, .header-item, .column-label-left, .project, #project-header {
    font-size: 0.64rem;
  } 
  .header-item {
    margin-top: 10vh;    
  height: 8vh;
  width: 14vw;   
  font-family: 'Archivo', sans-serif;  
  text-align: right;
  transform: rotate(-90deg);
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  left: -4vw;
  }
 .laura-font-about {
        position: relative;
        bottom: 4vh;   
        width: 90vw;
        left: 5.8vw;  
        height: 30vh;  
        filter: invert(99%) sepia(1%) saturate(0%) hue-rotate(257deg) brightness(105%) contrast(100%);
    }

    .laura-font-digital {
              position: relative;            
              width: 90vw;
              left: 5.8vw;        
              height: 30vh;
              bottom: 4vh; 
                          
              margin-bottom: 0vh;
              }



}

/* iPad */
@media only screen 
and (min-device-width: 768px) 
and (min-device-height: 1024px)
and (orientation: portrait)
/* and (-webkit-min-device-pixel-ratio: 2) */
 {
 
  .info, .left-text-box, .bio, .header-item, .column-label-left, .project, #project-header {
    font-size: 0.8rem;
  }

  .laura-font-about {
    position: relative;
    bottom: 4vh;       
    width: 90vw;
    left: 5.4vw;
    height: 18vh;    
    filter: invert(99%) sepia(1%) saturate(0%) hue-rotate(257deg) brightness(105%) contrast(100%);    
}


.laura-font-digital {
              position: sticky;            
              width: 90vw;
              left: 5.4vw;        
              height: 18vh;
              bottom: 4vh;              
              /* display: block; */
              margin-top: 4.8em;
              }
              
  .header-item {    
    text-align: center;
      top: 2.4vh;          
    }

    .column-label-left {
      position: absolute;
  left: 74vw;  
  }                                                 

  .client-column {  
    position: relative;  
    margin-left: 32vw;      
    text-align: right;                         
}       


  .rectangle {
    position: fixed;
    top: 94vh;
    width: 33vw;
    height: 6vh;        
    }
    
    .project {      
      grid-template-columns: 2fr 2fr;                      
  }    

  .project-image-landscape {
    margin-right: 0.6em;
  }

    .project-image-portrait {
      width: 10em;
        height: 14em;        
    }    
}

@media only screen 
and (min-device-height: 768px) 
and (min-device-width: 1024px)
and (orientation: landscape)
/* and (-webkit-min-device-pixel-ratio: 2) */
 {

   .projects-underline {
     width: 91.8vw;
   }

    .column-label-left {
      left: 87.8vw;
    }

    .project {
      width: 92vw;
    }

  .info, .left-text-box, .bio, .header-item, .column-label-left, .project, #project-header {
    font-size: 0.94rem;
  }


  .info, .left-text-box {
    top: 2vh;
  }

   /* .rectangle {
    position: relative;    
  } */

  /* .one, .two, .three 
  {
    top: 0;
  }  */

  
  .header-item {    
    /* top: 8vh; */    
    height: 4vh;    
    position: absolute;    
    word-wrap: break-word; 
    top: 6vh;  
    left: -4.6vw;    

    }

    .about-item {    
      position: absolute;          
      color: #FFFFFF;       
      
      }
      
      .digital-item { 
      position: absolute;      
      color: #000000;
      overflow: hidden;
      text-overflow: ellipsis;
      
      }
      
      .print-item {    
      position: absolute;             
      color: #000000;
      }

      .laura-font-about {
        position: sticky;
        bottom: 0vh;   
        width: 92vw;
        left: 6vw;  
        height: 28vh;  
        filter: invert(99%) sepia(1%) saturate(0%) hue-rotate(257deg) brightness(105%) contrast(100%);
    }

    .laura-font-digital {
              position: sticky;            
              width: 92vw;
              left: 6vw;        
              height: 28vh;             
              bottom: 0vh;
              display: block;
              margin-top: 3.2em;              
              }

              .project-image-landscape {      
        width: 16.5625em;    
        height: 10.25em;    
    }

    .project-image-portrait {
        height: 17em;        
        width: 12.8125em;
    }      

}

/* iPad Pro */

@media only screen 
and (min-device-width: 1024px) 
and (min-device-height: 1366px)
and (orientation: portrait)
/* and (-webkit-min-device-pixel-ratio: 0)  */
{
  .info, .left-text-box, .bio, .header-item, .column-label-left, .project, #project-header {
    font-size: 1rem;
  }

  .laura-font-digital {
    margin-top: 6.4em;
  }

  .header-item {    
    text-align: center;
      top: 2.4vh;                  
    }

}

@media only screen 
and (min-device-height: 1024px) 
and (min-device-width: 1366px)
and (orientation: landscape)
/* and (-webkit-min-device-pixel-ratio: 0) */
 {
  .info, .left-text-box, .bio, .header-item, .column-label-left, .project, #project-header {
    font-size: 1rem;
  }     
}

@media only screen 
and (min-width: 265px)
and (max-width: 400px)
and (orientation: landscape) {
  .info, .left-text-box, .bio, .header-item, .column-label-left, .project, #project-header {
    font-size: 0.2rem;
  }
  .laura-font-digital {
    margin-top: 0.8em;
  }
}      

 @media only screen 
and (min-width: 401px)
and (max-width: 501px)
and (orientation: landscape) {
  .info, .left-text-box, .bio, .header-item, .column-label-left, .project, #project-header {
    font-size: 0.4rem;
  }
}  

@media only screen 
and (min-width: 501px)
and (max-width: 601px)
and (orientation: landscape) {
  .info, .left-text-box, .bio, .header-item, .column-label-left, .project, #project-header {
    font-size: 0.6rem;
  }  
} 



  @media only screen 
and (min-width: 601px)
and (max-width: 701px)
and (orientation: landscape) {
  .info, .left-text-box, .bio, .header-item, .column-label-left, .project, #project-header {
    font-size: 0.6rem;
  }
  .laura-font-digital {
    margin-top: 2.2em;
  }
}

@media only screen 
and (min-width: 701px)
and (max-width: 801px)
and (orientation: landscape) {
  .info, .left-text-box, .bio, .header-item, .column-label-left, .project, #project-header {
    font-size: 0.66rem;
  }
  .laura-font-digital {
    margin-top: 2.4em;
  }
}


  

